import {PageProps} from '@/common/hooks/usePageTracking'

export type SamLoginPageId =
    | 'login-page'
    | 'registration-page'
    | 'tiktok-registration-page'
    | 'forgot-password-page'
    | 'new-password-page'
    | 'define-password-page'
    | 'verification-page'

export const LOGIN_PAGES: PageProps<SamLoginPageId>[] = [
    {
        id: 'login-page',
        path: '/',
    },
    {
        id: 'registration-page',
        path: '/register',
    },
    {
        id: 'tiktok-registration-page',
        path: '/tiktok/register',
    },
    {
        id: 'forgot-password-page',
        path: '/forgot-password',
    },
    {
        id: 'new-password-page',
        path: '/new-password',
    },
    {
        id: 'define-password-page',
        path: '/define-password',
    },
    {
        id: 'verification-page',
        path: '/verify',
    },
]
