import '../common/styles/SamStyles.scss'
import '@/common/manager/ConsoleMessage'
import '@/common/manager/I18n'
import {setTheWindowAnalyticsObjectBasedOnThePages} from '@/common/util/Tracking'
import SamLogin from '@/standalone-login/SamLogin'
import {LOGIN_PAGES} from '@/standalone-login/SamLoginPages'
import {render} from 'react-dom'

setTheWindowAnalyticsObjectBasedOnThePages(LOGIN_PAGES, document.location.pathname, 'initial-load')

const root = document.createElement('div')
root.classList.add('app')

document.body.appendChild(root)

render(<SamLogin />, root)
