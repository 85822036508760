import {getHistory} from '@/common/History'
import ErrorBoundary from '@/common/components/error-boundary/ErrorBoundary'
import {ToastContainer} from '@/common/components/toast/ToastContainer'
import {usePageTracking} from '@/common/hooks/usePageTracking'
import i18n from '@/common/manager/I18n'
import queryClient from '@/common/query/QueryClient'
import SamLoginConfig from '@/standalone-login/SamLoginConfig'
import {LOGIN_PAGES, SamLoginPageId} from '@/standalone-login/SamLoginPages'
import {QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {FunctionComponent, LazyExoticComponent, Suspense, lazy} from 'react'
import {ModalProvider} from 'react-modal-hook'
import {Redirect, Route, Router, Switch} from 'react-router-dom'
import {PageLoader} from 'spreadconnect-components'

const LoginPage = lazy(() => import('@/standalone-login/page/login/LoginPage'))
const ForgotPasswordPage = lazy(
    () => import('@/standalone-login/page/forgot-password/ForgotPasswordPage')
)
const NewPasswordPage = lazy(() => import('@/standalone-login/page/new-password/NewPasswordPage'))
const DefinePasswordPage = lazy(
    () => import('@/standalone-login/page/define-password/DefinePasswordPage')
)
const RegistrationPage = lazy(() => import('@/standalone-login/page/registration/RegistrationPage'))
const TiktokRegistrationPage = lazy(
    () => import('@/standalone-login/page/registration/tiktok/TikTokRegistrationPage')
)
const VerificationPage = lazy(() => import('@/standalone-login/page/verification/VerificationPage'))

const PAGE_COMPONENTS: Record<SamLoginPageId, LazyExoticComponent<FunctionComponent<object>>> = {
    'login-page': LoginPage,
    'registration-page': RegistrationPage,
    'tiktok-registration-page': TiktokRegistrationPage,
    'forgot-password-page': ForgotPasswordPage,
    'new-password-page': NewPasswordPage,
    'define-password-page': DefinePasswordPage,
    'verification-page': VerificationPage,
}

const SamLogin: FunctionComponent = () => {
    const history = getHistory({basename: SamLoginConfig.basePath})

    usePageTracking(LOGIN_PAGES, history)

    return (
        <QueryClientProvider client={queryClient}>
            <ErrorBoundary>
                <ModalProvider>
                    <Suspense
                        fallback={
                            <PageLoader
                                label={i18n.t('general.a11y.loading-content', {
                                    defaultValue: 'Loading',
                                })}
                            />
                        }
                    >
                        <Router history={history}>
                            <Switch>
                                <Route path="/login" exact component={() => <Redirect to="/" />} />
                                {LOGIN_PAGES.map((page, index) => {
                                    const PageComponent = PAGE_COMPONENTS[page.id]
                                    return (
                                        <Route
                                            key={index}
                                            path={page.path}
                                            exact
                                            component={PageComponent}
                                        />
                                    )
                                })}
                                <Redirect to="/" />
                            </Switch>
                        </Router>
                        <ToastContainer />
                    </Suspense>
                </ModalProvider>
            </ErrorBoundary>
            <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
    )
}

export default SamLogin
